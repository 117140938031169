<template>
  <div :style="{flex:1}" class="item">
    <div :style="{width: labelWidth}" class="item-left">{{ label }}</div>
    <span v-if="!url && value" :style="{marginRight: right}" class="item-right" style="font-size: 14px;color: rgba(0,0,0,.6);">{{ value }}</span>
    <el-image v-if="url" :preview-src-list="[url]" :src="url" style="width: 200px; height: 200px"></el-image>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "Item",
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String | Number,
      default: ""
    },
    labelWidth: {
      type: String,
      default: "112px"
    },
    width: {
      type: String,
      default: "463px"
    },
    url: {
      type: String,
      default: ""
    },
    right: {
      type: String,
      default: '48px'
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
display: flex;

.item-left {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.item-right {
  font-size: 14px !important;
  font-weight: 400;
  flex: 1;
  color: rgba(0, 0, 0, 0.6);
}
}
</style>
