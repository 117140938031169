<template>
  <t-edit-template v-if="dataCont" style="background: rgba(247, 247, 247, 1);padding-right: 40px">
    <div class="title" style="position: relative">
      <img class="bg" src="../../../../assets/img/fresh-registration-details-bg.png" alt="" />
      <div class="title-title">
        <div style="margin-top: 19px;margin-left: 24px">学生信息</div>
      </div>
      <div class="title-bottom flex flex-wrap">
        <div v-if="dataCont.student_info.student_name" class="box box-1"><span>姓名</span>{{ dataCont.student_info.student_name }}</div>
        <div v-if="dataCont.student_info.gender" class="box box-1"><span>性别</span>{{ dataCont.student_info.gender }}</div>
        <div v-if="dataCont.student_info.birthday" class="box box-1"><span>出生日期</span>{{ dataCont.student_info.birthday }}</div>
        <div v-if="dataCont.student_info.student_nation" class="box box-1"><span>民族</span>{{ dataCont.student_info.student_nation }}</div>
        <div v-if="dataCont.student_info.student_problem" class="box box-3"><span>重大疾病史</span>{{ dataCont.student_info.student_problem }}</div>
        <div v-if="dataCont.student_info.student_register_address" class="box box-3"><span>户籍地</span>{{ dataCont.student_info.student_register_address }}</div>
        <div v-if="dataCont.student_info.school_name" class="box box-3"><span>报名校区</span>{{ dataCont.student_info.school_name }}</div>
        <div v-if="dataCont.student_info.grade_name" class="box box-1"><span>年级</span>{{ dataCont.student_info.grade_name }}</div>
        <div v-if="dataCont.student_info.entre_year" class="box box-1"><span>入学年份</span>{{ dataCont.student_info.entre_year }}</div>
      </div>
    </div>

    <div class="title" style="position: relative">
      <div class="title-title">
        <div style="margin-top: 19px;margin-left: 24px">家长信息</div>
      </div>
      <div class="title-bottom flex flex-wrap">
        <div v-if="dataCont.parent_info.parent_name" class="box box-2"><span>姓名</span>{{ dataCont.parent_info.parent_name }}</div>
        <div v-if="dataCont.parent_info.relationship" class="box box-2"><span>与学生的关系</span>{{ dataCont.parent_info.relationship }}</div>
        <div v-if="dataCont.parent_info.student_communication_address" class="box box-2"><span>家庭住址</span>{{ dataCont.parent_info.student_communication_address }}</div>
        <div v-if="dataCont.parent_info.sign_phone" class="box box-2"><span>签约手机号</span>{{ dataCont.parent_info.sign_phone}}</div>
        <div v-if="dataCont.parent_info.parent_contact" class="box box-2"><span>联系电话</span>{{ dataCont.parent_info.parent_contact }}</div>
      </div>
    </div>

    <div class="title" style="position: relative">
      <div class="title-title">
        <div style="margin-top: 19px;margin-left: 24px">订单信息</div>
      </div>
      <div class="title-bottom flex flex-wrap">
        <div v-if="dataCont.order_info.order_number" class="box box-2"><span>订单号</span>{{ dataCont.order_info.order_number }}</div>
        <div v-if="dataCont.order_info.order_status" class="box box-2"><span>订单状态</span>{{ dataCont.order_info.order_status }}</div>
        <div v-if="dataCont.order_info.sign_teacher_name" class="box box-2"><span>签约招生老师</span>{{ dataCont.order_info.sign_teacher_name }}</div>
        <div v-if="dataCont.order_info.belong_teacher_name" class="box box-2"><span>客户归属人</span>{{ dataCont.order_info.belong_teacher_name }}</div>
        <div v-if="dataCont.order_info.order_m" class="box box-2"><span>支付订单号</span>{{ dataCont.order_info.order_m }}</div>
        <div v-if="dataCont.order_info.success_time_pay" class="box box-2"><span>支付成功时间</span>{{ dataCont.order_info.success_time_pay }}</div>
        <div v-if="dataCont.order_info.money" class="box box-2"><span>支付金额</span>{{ dataCont.order_info.money }}</div>
        <div v-if="dataCont.order_info.pay_mode" class="box box-2"><span>支付方式</span>{{ dataCont.order_info.pay_mode }}</div>
        <div v-if="dataCont.order_info.payer_type" class="box box-2"><span>支付人</span>{{ dataCont.order_info.payer_type }}</div>
        <div v-if="dataCont.order_info.is_signed" class="box box-2"><span>是否签约</span>{{ dataCont.order_info.is_signed }}</div>
        <div v-if="dataCont.order_info.contract_num" class="box box-2"><span>协议编号</span>{{ dataCont.order_info.contract_num }}</div>
        <div v-if="dataCont.order_info.sign_success_time" class="box box-2"><span>签约成功时间</span>{{ dataCont.order_info.sign_success_time }}</div>
      </div>
    </div>

    <div class="button" slot="footer">
      <el-button @click="$router.back()">返回</el-button>
      <el-button type="primary" @click="locationTo">查看协议</el-button>
    </div>

  </t-edit-template>
</template>
<script>
import Item from "@/views/finance/enrollment/payment-progress/components/Item.vue";
import ListTemplate from "@/components/pages/List.vue"
import TEditTemplate from "@/components/pages/Edit";

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  components: {TEditTemplate, ListTemplate, Item},
  data() {
    return {
      OSS_URL: process.env["VUE_APP_URL_OSS"],
      dataCont: ''
    }
  },

  created() {
    this.$_register.get('api/recruit/promise-pass/show?order_id=' + this.$route.query.id).then(res => {
      this.dataCont = res.data.data;
    })
  },

  methods: {
    locationTo(){
      window.open(this.dataCont.order_info.contract_addr, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.title-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #F7F7F7;
  border-radius: 10px 10px 0 0;
  background: #FFF;
  box-shadow: 2px 2px 2px 1px rgba(232, 237, 255, 0.16);
}

.title-bottom {
  box-sizing: border-box;
  margin-bottom: 24px;
  //padding-top: 28px;
  padding-bottom: 24px;
  padding-left: 32px;
  border-radius: 0 0 10px 10px;
  background: #FFF;
}

.body {
  margin: 24px 0;

  & .body-title {
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    height: 78px;
    padding-top: 19px;
    padding-left: 24px;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid #F7F7F7;
    border-radius: 10px 10px 0 0;
    background: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(232, 237, 255, 0.16);
  }

  & .body-bottom {
    box-sizing: border-box;
    min-height: 148px;
    padding: 0 24px 24px;
    border-radius: 0 0 10px 10px;
    background: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(232, 237, 255, 0.16);

    & .body-bottom-label {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-size: 14px;
      font-weight: 400;
      width: 96px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.el-table__body .cell {
  white-space: normal;
  word-wrap: break-word;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 220px;
  height: 60px;
}

.box-1{
  width: 25%;
}
.box-2{
  width: 33.3%;
}
.box-3{
  width: 50%;
}
.box{
  font-size: 14px;
  color: rgba(0,0,0,.6);
  margin-top: 24px;

  span{
    margin-right: 30px;
    color: rgba(0,0,0,.8);
  }
}
</style>
